import { createRouter,createWebHashHistory } from 'vue-router'

const routes = [
    {
        name: 'Login',
        path: '/login',
        component: () => import('@/views/login/indexView.vue')
    },
    {
        name: 'Layout',
        path: '/',
        component: () => import('@/layout/indexView.vue'),
        redirect: '/contract',
        children: [
            { path: '/contract', component: () => import('@/views/contract/indexView.vue')},
        ]
    },
    {
        name: 'ContractDetail',
        path: '/contract/detail',
        component: () => import('@/views/contract/detailView.vue')
    },
    {
        name: 'ContractImageDetail',
        path: '/contract/image-detail',
        component: () => import('@/views/contract/imageDetailView.vue')
    },
    {
        name: 'ContractPreview',
        path: '/contract/preview',
        component: () => import('@/views/contract/previewView.vue')
    },
    {
        name: 'ContractConsumer',
        path: '/consumer/:uuid/',
        component: () => import('@/views/consumer/indexView.vue')
    },
    {
        name: 'PreCreateConsumer',
        path: '/consumer/pre-create/:uuid/',
        component: () => import('@/views/consumer/preCreateView.vue')
    },
    {
        name: 'ContractConsumerBlank',
        path: '/consumer/blank/',
        component: () => import('@/views/consumer/blankView.vue')
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
})

export default router
